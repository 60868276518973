import { styled } from "@mui/material";

export const Item = styled('div')(({ theme, children, ...otherProps }) => ({
    ...theme.typography.h5,
    //textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: 16,
    ...otherProps,
    [theme.breakpoints.down('lg')]: {
        fontSize: 16
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: 14
    }
}));

export const ColorValue = styled('div')(({ styleProps }) => ({
    ...styleProps,
    width: "fit-content",
    borderRadius: 4,
    display: 'flex',
    padding: "5px 10px",
    boxSizing: 'border-box',
    alignItems: 'center',
    columnGap: 4,
}));