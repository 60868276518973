import React, { useState } from 'react';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { TextField, Typography, Tooltip } from '@mui/material';
import { styled } from "@mui/material/styles";
import "./styles.scss";
import { setSymbolOnEmpty, combine, INDIAN_COUNTRY_CODE,get_time_to_sec } from '../../utils';
import { getUser, post } from '../../AppRedux/Actions';
import { useDispatch } from 'react-redux';
import { EDIT_SINGLE_ATTENDANCE, LEAVE_RULE_TYPELIST_BALANCE } from '../../constants';
import { When } from 'react-if';

import { usePayrollHook } from '../../hooks/usePayrollHook';
import FormBuilder from '../../components/FormBuilder/FormBuilder';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import SubmitButton from '../../components/Buttons/CustomButton';

const NoteSection = styled('div')({
    fontSize: 15,
    marginTop: 20,
    color: "red",
    borderRadius: "0.12rem",
    padding: "0.25rem",
    backgroundColor: "#ff000017",
    border: "1px solid #ff000066",
});

const Note = styled('div')({
    display: 'inline',
    fontWeight: 'bold',
    color: 'red',
    marginRight: 5
});

const InputContainer = styled('div')({
    marginTop: 20
});

const InputSection = styled('div')({
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 14,
    marginTop: 12,
    marginBottom: 12
});

const InputValue = styled('div')({
    fontSize: 14
});

const InputName = styled('div')({
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    columnGap: 5
});

const ButtomButton = styled('div')({
    width: '100%',
    fontSize: 14,
    marginTop: 2,
    position: 'sticky',
    background: "white",
    right: 0,
    bottom: 0
});

function EditEmployee(props) {

    const { filterResult, filterResult: { month_year }, OrgConfig, employeeList, setEmployeeList } = usePayrollHook();
    const ACTIVE_COUNTRY = getUser('active_country_id');
    // const [leaveEncash, setLeaveEncash] = useState({});
    // const [rowSelectList, setRowSelectList] = useState([]);
    const [leaveType, setLeaveType] = useState([]);
    const [leaveTypeObject, setLeaveTypeObject] = useState({});
    const [leaveValues, setLeaveValues] = useState([]);
    const [selectedLeaves, setSelectedLeaves] = useState({});
    const { editEmployeeDetail, handleCloseRightDrawer, fetchEmployee } = props;
    const { emp_name,
        emp_code,
        department_name,
        emp_pay_day,
        working_day_count,
        present_day_count,
        leave_day_count,
        panalized_day_count,
        lop_day_count,
        over_time,
        leave_encash_count,
        leave_encash_data = [],
        extra_over_time,
        employee_id,
        emp_pay_status,
        salary,
        is_ot_applicable
    } = editEmployeeDetail;
    const { calculation_type } = salary?.[0]?.ot_rule ?? {};
    const [editableData, setEditableData] = useState({
        lop_day_count,
        over_time: over_time_formate('over_time'),
        extra_over_time: over_time_formate('extra_over_time'),
        leave_encash_count: leave_encash_count
    });
    const dispatch = useDispatch();


    const addLeaveEncahment = () => {
        setLeaveValues([...leaveValues, { leave_id: '', leave_count: '' }])
    }
    const removeLeaveEncahment = (e, index, leave_id) => {
        let leaveValuess = leaveValues;
        setEditableData({ ...editableData, leave_encash_count: Number(editableData.leave_encash_count) - Number(leaveValuess[index].leave_count || 0) })
        leaveValuess.splice(index, 1);
        setLeaveValues([...leaveValuess])
        let selectedLeavess = selectedLeaves;
        delete selectedLeavess[leave_id];
        setSelectedLeaves(selectedLeavess)

    }
    const editLeaveEncahment = () => {

        if (leaveType.length <= 0) {
            const payload = {
                employee_id,
                is_payroll: true
            }
            post(dispatch, '', LEAVE_RULE_TYPELIST_BALANCE, payload, {}, (data) => {
                if (data && Object.values(data).length > 0) {
                    let leaveTypes = data.map(
                        (item) => {
                            return {
                                id: item['LEAVE_ID'],
                                name: `${item['LEAVE_TYPE_NAME']} (${item.TOTAL_REMAINING})`,
                                balance: Number(item?.TOTAL_REMAINING || 0) + Number(item?.LEAVE_TAKEN || 0)
                            }
                        }
                    )
                    setLeaveType(leaveTypes)
                    if (leaveTypes.length > 0) {
                        let leaveObject = combine(leaveTypes, '{n}.id', '{n}');
                        setLeaveTypeObject(leaveObject)
                        let selectedLeaves = {}
                        let leaveValues = []
                        if (leave_encash_data.length > 0) {
                            leave_encash_data.map((item) => {
                                if (leaveObject.hasOwnProperty(item.LEAVE_ID)) {
                                    selectedLeaves[item.LEAVE_ID] = item;
                                    leaveValues.push({
                                        leave_id: item.LEAVE_ID,
                                        leave_count: item.total_leave || 0,
                                        saved: 1,
                                        balance: leaveObject[item.LEAVE_ID]?.balance || 0
                                    })
                                }
                            });
                        }
                        // else {
                        //     leaveValues.push({
                        //         leave_id: '',
                        //         leave_count: 0
                        //     })
                        // }
                        leaveValues.push({
                            leave_id: '',
                            leave_count: 0
                        })
                        setSelectedLeaves(selectedLeaves)
                        setLeaveValues(leaveValues)
                    }

                }
            });
        } else {
            let leaveObject = { ...leaveTypeObject }
            let selectedLeaves = {}
            let leaveValues = []
            if (leave_encash_data.length > 0) {
                leave_encash_data.map((item) => {
                    if (leaveObject.hasOwnProperty(item.LEAVE_ID)) {
                        selectedLeaves[item.leave_id] = item;
                        leaveValues.push({
                            leave_id: item.LEAVE_ID,
                            leave_count: item.total_leave || 0,
                            saved: 1,
                            balance: leaveObject[item.LEAVE_ID]?.balance || 0

                        })
                    }
                });
            } else {
                leaveValues.push({
                    leave_id: '',
                    leave_count: 0
                })
            }
            setSelectedLeaves(selectedLeaves)
            setLeaveValues(leaveValues)
        }
    }
    const handleOnEditform = () => {
        let postdata = {
            ...editableData,
            emp_code,
            employee_id,
            month_year,
            old_values: { lop_day_count, over_time, extra_over_time }
        }
        if (leaveValues.length > 0) {
            postdata.leave_encashment = leaveValues;
        }
        post(dispatch, '', EDIT_SINGLE_ATTENDANCE, { e_data: postdata, filters: filterResult }, {}, (data) => {
            const employee = data?.emp_attd || {};
            setEmployeeList({ ...employeeList, ...employee });
            // handleCloseRightDrawer();
        });
    }

    const handleOnChangeValue = (e) => {
        const { name, value } = e.target;
        setEditableData((prev) => {
            return {
                ...prev, [name]: value
            }
        })
    }
    const handleLeaveEncash = (e, index, item) => {
        let { leave_id } = item
        let leaveValuess = leaveValues;
        let editableDatas = {}
        let changes = false;
        if (e.target.name == 'leave_id') {

            editableDatas = { ...editableData, leave_encash_count: Number(editableData.leave_encash_count) - Number(leaveValuess[index].leave_count || 0) }
            leaveValuess[index] = { leave_id: e.target.value, leave_count: 0, saved: leaveValuess[index].saved || 0, balance: leaveTypeObject[e.target.value].balance }
            changes = true;
        } else {
            if (e.target.value <= leaveValuess[index].balance) {
                editableDatas = { ...editableData, leave_encash_count: Number(editableData.leave_encash_count) - Number(leaveValuess[index].leave_count || 0) + Number(e.target.value) }
                leaveValuess[index] = { ...leaveValuess[index], leave_count: e.target.value }
                changes = true;
            }
        }
        if (changes) {
            setLeaveValues([...leaveValuess])
            setEditableData(editableDatas)
        }
        if (e.target.name == 'leave_id') {
            let selectedLeavess = selectedLeaves;
            delete selectedLeavess[leave_id];
            selectedLeavess = { ...selectedLeavess, [e.target.value]: leaveValues[index] }
            setSelectedLeaves({ ...selectedLeavess })

        }
    }

    function over_time_formate(name) {
        if (is_ot_applicable) {
            const { calculation_type, max_day_hour_eligiblity } = salary?.[0]?.ot_rule ?? {};

            return Math.round((editEmployeeDetail[name] || 0) * 100 / (calculation_type == 2 ? get_time_to_sec(max_day_hour_eligiblity) : 3600)) / 100;

        }
        else return "0"
    }

    return (
        <Box sx={{ width: 400 }} role="presentation" className="edit-employee">
            <Box>
                <Box sx={{ float: 'right', m: 1, cursor: 'pointer' }}><CloseIcon onClick={handleCloseRightDrawer} /></Box>
            </Box>
            <Box sx={{ p: 2, mt: 4 }}>
                <Box>
                    <Stack direction="row" justifyContent="space-between">
                        <Typography variant='h5' component="div" sx={{ color: '#4D70FF', textTransform: 'capitalize' }}>{setSymbolOnEmpty(emp_name, "-")}</Typography>
                        <Typography variant='h5' component="div" sx={{ textTransform: 'capitalize' }}>{setSymbolOnEmpty(department_name, "-")}</Typography>
                    </Stack>
                </Box>
                <Box sx={{ fontSize: 14 }}>Emp  Code :{setSymbolOnEmpty(emp_code, "-")}</Box>
                <NoteSection>
                    <Note>NOTE:</Note> {emp_pay_status != 'Pending' ? 'First Recompute Attendance to edit LOP/Overtime/Extra Overtime/Leave Encashment.' : 'Once you edit LOP count. HRMS attendance will be discarded.'}
                </NoteSection>
                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ backgroundColor: '#E6ECFF26', mt: 3, p: 0.5 }}>
                    <Typography variant='h5' component="div" sx={{ color: '#80ACFF' }}>Details</Typography>
                    {/* <Typography component="div" sx={{ color: '#80ACFF' }}><ModeEditOutlineOutlinedIcon /></Typography> */}
                </Stack>
                <InputContainer>
                    <InputSection>
                        <InputName>Payable Days</InputName>
                        <InputValue>{setSymbolOnEmpty(emp_pay_day, "-")}</InputValue>
                    </InputSection>
                    <InputSection>
                        <InputName>Working  Days</InputName>
                        <InputValue>{setSymbolOnEmpty(working_day_count, "-")}</InputValue>
                    </InputSection>
                    <InputSection>
                        <InputName>Payable Present</InputName>
                        <InputValue>{setSymbolOnEmpty(present_day_count, "-")}</InputValue>
                    </InputSection>
                    <InputSection>
                        <InputName>Leaves</InputName>
                        <InputValue>{setSymbolOnEmpty(leave_day_count, "-")}</InputValue>
                    </InputSection>
                    <InputSection>
                        <InputName>Penalty</InputName>
                        <InputValue>{setSymbolOnEmpty(panalized_day_count, "-")}</InputValue>
                    </InputSection>
                    <InputSection>
                        <InputName>LOP Days</InputName>
                        <InputValue sx={{ width: "25%" }}>
                            <Tooltip title={emp_pay_status != 'Pending' ? 'First Recompute Attendance to edit LOP' : (OrgConfig?.MANUAL_LOP == 0 ? 'Manual LOP Disabled' : '')} arrow placement="top" sx={{ cursor: (emp_pay_status != 'Pending' || OrgConfig?.MANUAL_LOP == 0) ? "no-drop" : 'default' }}>
                                <TextField
                                    hiddenLabel
                                    size="small"
                                    name='lop_day_count'
                                    disabled={emp_pay_status != 'Pending' || OrgConfig?.MANUAL_LOP == 0}
                                    value={editableData?.lop_day_count}
                                    onChange={handleOnChangeValue}
                                />
                            </Tooltip>
                        </InputValue>
                    </InputSection>
                    <When condition={ACTIVE_COUNTRY == INDIAN_COUNTRY_CODE}>
                        <InputSection>
                            <InputName>Overtime <span style={{ color: "green", fontWeight: 600 }}>({calculation_type == 2 ? "Days" : "Hours"})</span></InputName>
                            <InputValue sx={{ width: "25%" }}>
                                <Tooltip title={emp_pay_status != 'Pending' ? 'First Recompute Attendance to edit Overtime' : (!is_ot_applicable ? 'Overtime Pay Disabled' : (!salary?.[0].OT_PLAN ? 'OT Plan not mapped' : ''))} arrow placement="top" sx={{ cursor: (emp_pay_status != 'Pending' || !is_ot_applicable || !salary?.[0].OT_PLAN) ? "no-drop" : 'default' }}>
                                    <TextField
                                        hiddenLabel
                                        size="small"
                                        variant="outlined"
                                        disabled={emp_pay_status != 'Pending' || !is_ot_applicable || !salary?.[0].OT_PLAN}
                                        name='over_time'
                                        value={editableData?.over_time}
                                        onChange={handleOnChangeValue}
                                    />
                                </Tooltip>
                            </InputValue>
                        </InputSection>
                        <InputSection>
                            <InputName>Extra OT <span style={{ color: "green", fontWeight: 600 }}>({calculation_type == 2 ? "Days" : "Hours"})</span></InputName>
                            <InputValue sx={{ width: "25%", cursor: emp_pay_status != 'Pending' ? "no-drop" : 'default' }}>
                                <Tooltip title={emp_pay_status != 'Pending' ? 'First Recompute Attendance to edit Extra Overtime' : (!is_ot_applicable ? 'Overtime Pay Disabled' : (!salary?.[0].OT_PLAN ? 'OT Plan not mapped' : ''))} arrow placement="top" sx={{ cursor: (emp_pay_status != 'Pending' || !is_ot_applicable || !salary?.[0].OT_PLAN) ? "no-drop" : 'default' }}>
                                    <TextField
                                        hiddenLabel
                                        size="small"
                                        variant="outlined"
                                        disabled={emp_pay_status != 'Pending' || !is_ot_applicable || !salary?.[0].OT_PLAN}
                                        name='extra_over_time'
                                        value={editableData?.extra_over_time}
                                        onChange={handleOnChangeValue}
                                    />
                                </Tooltip>
                            </InputValue>
                        </InputSection>
                    </When>
                    <InputSection>
                        <InputName>
                            Leave Encashment
                            <Tooltip title={emp_pay_status != 'Pending' ? 'First Recompute Attendance to edit Leave Encashment' : ''} arrow placement="top" sx={{ cursor: emp_pay_status != 'Pending' ? "no-drop" : 'default' }}>
                                <Box className="icon-rounded" onClick={() => {
                                    if (emp_pay_status == 'Pending') {
                                        editLeaveEncahment()
                                    }
                                }} sx={{ padding: '5px', cursor: emp_pay_status != 'Pending' ? "no-drop !important" : 'default' }}>
                                    <ModeEditOutlineOutlinedIcon sx={{ fontSize: 20, color: '#4D8BFF' }} />
                                </Box>
                            </Tooltip>
                        </InputName>
                        <InputValue>{editableData?.leave_encash_count || 0} </InputValue>


                    </InputSection>
                    <InputSection className="leave-encash">
                        <Box>
                            {
                                leaveValues.map((item, indexCount) => {
                                    return (
                                        <Stack direction="row" justifyContent="space-between" rowGap={2} sx={{ width: "100%", marginTop: '10px' }}>
                                            {
                                                <FormBuilder size={[6, 6, 6, 6]} fields={
                                                    [
                                                        {
                                                            type: 'select', name: 'leave_id', label: 'Leave Type', options: leaveType.filter((leave) => {
                                                                return !selectedLeaves.hasOwnProperty(leave.id) || leave.id == item.leave_id;
                                                            }), disabled: item.saved
                                                        },
                                                        { type: 'input', name: 'leave_count', validate: 'N', label: '', disabled: !item.leave_id }
                                                    ]
                                                } values={leaveValues[indexCount]} inputHandler={(e) => { handleLeaveEncash(e, indexCount, item) }} />
                                            }
                                            <Box className={"icon-rounded-transparent"} sx={{ padding: '5px', marginTop: '10px', marginLeft: '10px' }}>
                                                {!item.saved ? <RemoveCircleOutlineIcon onClick={(e) => { removeLeaveEncahment(e, indexCount, item.leave_id) }} sx={{ fontSize: 20, color: '#FF3333' }} /> : null}
                                            </Box>
                                        </Stack>
                                    );
                                })
                            }
                        </Box>
                        {leaveValues.length > 0 ? <Box className="icon-rounded" onClick={addLeaveEncahment} sx={{ padding: '5px', marginTop: '15px' }}>
                            <AddCircleOutlineRoundedIcon sx={{ fontSize: 20, color: '#4D8BFF' }} />
                        </Box> : null}

                    </InputSection>

                </InputContainer>
                <ButtomButton>
                    <Divider />
                    <Stack direction="row" justifyContent="flex-end" gap={2} sx={{ mt: 1, p: 1 }}>
                        <SubmitButton variant="outlined" onClick={handleCloseRightDrawer}>Cancel</SubmitButton>
                        <SubmitButton variant="contained" onClick={handleOnEditform}>Save</SubmitButton>
                    </Stack>
                </ButtomButton>
            </Box>
        </Box>
    )
}

export default EditEmployee